import { useState } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { theme } from '@/styles/theme'

const styles = {
  root: {
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
  },
  input: {
    fontWeight: 'lighter',
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: '10px',
  },
  divider: {
    height: '28px',
    margin: '4px',
  },
  title: {
    fontWeight: 'lighter',
  },
}

const IntroNewsletterSlick = () => {
  const [email, setEmail] = useState('')

  const handleEmailChange = (event) => {
    event.persist()
    setEmail(event.target.value)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={styles.title}>Get Notified on Launch</div>
      </Grid>

      <Grid item xs={12}>
        <MailchimpSubscribe
          url={
            'https://unrealcloud.us2.list-manage.com/subscribe/post?u=12a95ea7b118d6f425bdb2d7b&id=86169b757d'
          }
          render={({ subscribe, status, message }) => (
            <>
              {status === 'success' && (
                <Alert severity="success">{`Successfully subscribed ${email}`}</Alert>
              )}

              {status === 'error' && <Alert severity="error">{message}</Alert>}

              <Paper
                component={'form'}
                sx={styles.root}
                onSubmit={(event) => {
                  event.persist()
                  event.preventDefault()
                  subscribe({ EMAIL: email })
                  setEmail('')
                }}
              >
                <InputBase
                  sx={styles.input}
                  placeholder="Email"
                  inputProps={{ 'aria-label': 'email' }}
                  value={email}
                  onChange={handleEmailChange}
                />

                <Divider sx={styles.divider} orientation={'vertical'} />

                <IconButton
                  type="submit"
                  color="primary"
                  sx={styles.iconButton}
                  disabled={!email || status === 'sending'}
                >
                  <ArrowForwardRoundedIcon />
                </IconButton>
              </Paper>
            </>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default IntroNewsletterSlick
