import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { TOOLBAR_HEIGHT } from '@/styles/theme'
import IntroNewsletterSlick from '../IntroNewsletterSlick'
import Keyboard from '../keyboard'

const Hero = () => (
  <Container>
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      height={`calc(100vh - ${TOOLBAR_HEIGHT * 2}px)`}
    >
      <Typography component="span" fontWeight="lighter" noWrap>
        CI/CD Service for{' '}
        <Typography component="span" fontWeight="normal" color="primary">
          Unreal Engine
        </Typography>
      </Typography>

      <div>
        <Typography
          fontSize={30}
          display="block"
          marginBottom={6}
          letterSpacing={5}
          overflow="visible"
          textAlign="center"
          noWrap
        >
          <Keyboard>W</Keyboard>
          rite Unreal Code
        </Typography>
        <Typography fontSize={30} letterSpacing={5} overflow="visible" textAlign="center" noWrap>
          <Keyboard>A</Keyboard>nd
          <Keyboard>S</Keyboard>treamline Buil<Keyboard>D</Keyboard>s
        </Typography>
      </div>

      <div>
        <IntroNewsletterSlick />
      </div>
    </Grid>
  </Container>
)

export default Hero
