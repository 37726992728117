import Head from 'next/head'
import Header from '../components/header'
import Hero from '../components/home/hero'

const Home = () => (
  <>
    <Head>
      <title>Unreal Cloud</title>
      <meta name="description" content="CI/CD for Unreal Engine" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/favicon.ico" />
    </Head>

    <Header />

    <Hero />
  </>
)

export default Home
